<template>
	<div id="app">
		<jy-top :noTopRoute="noTopRoute" :logo="getLogo" @logout="logout" :rightText="rightText">
			<jy-menu :menu="menu" mode="horizontal" backgroundColor="#05265f" activeTextColor="#fff"></jy-menu>
		</jy-top>
		<!-- <div>当前菜单：</div> -->
		<keep-alive>
			<router-view />
		</keep-alive>
	</div>
</template>
<script>
import logo from "@/assets/logo_1.png";
import { mapMutations, mapGetters } from "vuex";
export default {
	data() {
		return {
			noTopRoute: ["login", "dashboard", "line", "departRemind"], // 不需要 top 导航栏的路由 name
			logo
		};
	},
	computed: {
		rightText: function () {
			let text = "";
			if (this.$store.state && this.$store.state.info) {
				text = this.$store.state.info.markNa;
			}
			return text;
		},
		...mapGetters(["userInfoGet"]),
		getLogo() {
			var userInfo = this.userInfoGet || {};
			if (userInfo && userInfo.userId) {
				return userInfo.image || logo;
			} else {
				userInfo = this.$util.getStor("vplanAdminInfo") || userInfo;
			}
			return userInfo.userLogoImg;
		},
		menu() {
			let menuList = this.$store.state.menuList;
			return menuList;
		}
	},
	methods: {
		...mapMutations(["setMenuShow"]),
		logout() {
			let url = "/sys/user/loginOut";
			this.$http.post(url).then(() => {
				this.$util.removeStor("token");
				this.$store.state.info = null;
				this.$store.state.treeList = [];
				this.$store.state.menuList = [];
				window.location.reload();
				this.$router.push({ name: "login" });
			});
		}
	}
};
</script>
<style lang="scss">
#app {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	display: flex;
	flex-direction: column;
	height: 100vh;
}
.el-menu.el-menu--horizontal {
	border-bottom: none;
}
#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

[class^="jy-"] {
	font-family: "jy" !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.jy {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}

ul.pop_select_ul {
	li {
		padding: 4px;
		@extend .hand;

		&:hover {
			background: #efefef;
		}
	}
}
</style>
