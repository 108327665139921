import Vue from "vue";
import Vuex from "vuex";
import Http from "@/common/js/http";
import Util from "@/common/js/util.js";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		info: null,
		menuShow: true,
		treeList: [],
		menuList: [],
		hourOverShiftTotal: null,
		hourSpeed: null,
		basicView: null,
        isMockData: false
	},
	mutations: {
		setInfo(state, obj) {
			state.info = obj;
		},
		setMenuShow(state, b) {
			if (b !== undefined) {
				state.menuShow = !state.menuShow;
			} else {
				state.menuShow = !!b;
			}
		},
		addTree(state, list) {
			state.treeList = list;
		},
		resetTree(state) {
			state.treeList = [];
		},

		setMenuTree(state, obj) {
			state.menuList = obj.menu;
		},
		clearState(state) {
			state.info = null;
			state.menuShow = true;
			state.treeList = [];
			state.menuList = [];
		},
		setHourOverShiftTotal(state, obj) {
			state.hourOverShiftTotal = obj;
		},
		setHourSpeed(state, obj) {
			state.hourSpeed = obj;
		},
		setBasicView(state, obj) {
			state.basicView = obj;
		},
        setMockFlag(state, flag) {
            state.isMockData = flag;
        }
	},
	getters: {
		userInfoGet(state) {
			return state.info;
		},
		userId(state) {
			if (state.info) {
				return state.info.userId;
			} else {
				return "";
			}
		},
		getTree(state) {
			var list = state.treeList;
			return list;
		},
		getHourOverShiftTotal(state) {
			return state.hourOverShiftTotal;
		},
		getHourSpeed(state) {
			return state.hourSpeed;
		},
		isAdmin(state) {
			return state.info.isAdmin === "1";
		}
	},
	actions: {
		getInfo({ commit }) {
			return new Promise((resolve, reject) => {
				let url = "/sys/user/getCurrentUser";
				Http.post(url, {})
					.then(data => {
						commit("setInfo", data.detail);
						Util.setStor("vplanAdminInfo", data.detail);
						resolve(data);
					})
					.catch(error => {
						reject(error);
					});
			});
		},
		//左侧树菜单
		getMenuTree({ commit }) {
			return new Promise((resolve, reject) => {
				let url = "/sys/role/pageQueryMenu";
				let option = {
					pageSize: 0,
					pageIndex: 0,
					sysMenu: {
						tType: "1"
					}
				};
				Http.post(url, option, { isRequestParam: false })
					.then(({ detail }) => {
						console.log("user menu:", detail);
            detail.forEach((item, index) => {
              if(item.data.name === '首页' && index !== 0) {
                detail.splice(index, 1)
                detail.splice(0, 0, item)
              }
              if(item.data.name === '驾驶舱' && index !== 0) {
                detail.splice(index, 1)
                detail.splice(1, 0, item)
              }
            })
						function arrange(list) {
							let newList = [];
							list.forEach(item => {
								if (item.tType != "2") {
									newList.push(item);
								}
							});
							newList.forEach(item => {
								item.text = item.data.name;
								item.url = item.data.url;
								if (item.children && item.children.length) {
									item.children = arrange(item.children);
								}
							});
							return newList;
						}
						detail = arrange(detail);
						commit("setMenuTree", { menu: detail });
						resolve(detail);
					})
					.catch(error => {
						reject(error);
					});
			});
		},
		getHourOverShiftTotal({ commit }) {
			function inside() {
				let url = "/home/getHourOverShiftTotal";
				Http.post(url)
					.then(data => {
						if (data.code == 0) {
							commit("setHourOverShiftTotal", data.detail);
						}
					})
					.catch(error => {
						reject(error);
					});
				url = "/home/getHourSpeed";
				Http.post(url)
					.then(data => {
						if (data.code == 0) {
							commit("setHourSpeed", data.detail);
						}
					})
					.catch(error => {
						reject(error);
					});
				setTimeout(inside, 3600000);
			}
			inside();
			// 3600000
		}
	}
});
